import React from "react";
import heroImage from "../assets/hero.png";
// ------------------------------- Component Contents -------------------------------
const heading = "Bringing the World's Best Food and Utensils to Your Doorstep";
const subHeading =
  "Importing and Exporting High-Quality Products at Affordable Prices";
const cta = "Learn More";
const phone = "+977-9861774542";
// ------------------------------- Component Contents Ends-------------------------------
function Hero() {
  return (
    <section
      id="hero"
      className="px-3 md:px-0 h-[100vh] w-full bg-cover bg-center bg-no-repeat bg-fixed bg-hero-pattern bg-opacity-10 bg-slate-500 bg-blend-multiply"
      style={{ backgroundImage: `url(${heroImage})` }}
    >
      <div className="container mx-auto px-3 md:px-32 font-ques tracking-wide flex flex-col items-center justify-center space-y-3 md:space-y-8 pt-[200px] md:pt-[250px]">
        <h1 className="text-4xl md:text-7xl text-center font-bold text-white">{heading}</h1>
        <h1 className="text-lg md:text-3xl text-white text-center">{subHeading}</h1>
        <a href="#" className="hidden md:block text-xl bg-main rounded px-8 py-4 text-white">{cta}</a>
        <a href={`tel:${phone}`} className="block md:hidden text-xl bg-main rounded px-8 py-4 text-white">{phone}</a>
      </div>
    </section>
  );
}

export default Hero;
