import React from "react";
const contact = "+977-9861774542";

function Cta() {
  return (
    <div className="font-ques">
      <section className="py-16 bg-white overflow-hidden">
        <div className="container px-4 mx-auto">
          <div className="text-center max-w-3xl mx-auto">
            <h2 className="mb-5 text-3xl md:text-5xl font-bold font-heading text-center tracking-px-n leading-tight">
              Feel free to contact us for any queries you have
            </h2>
            <p className="mb-7 text-lg text-gray-600 font-medium">
            At D.D Suppliers, we are committed to sustainability and ethical business practices. We work closely with our suppliers to ensure that all of our products are produced in an environmentally responsible way, and we are committed to fair labor practices and the well-being of our employees.
            </p>
            <div className="mb-11 md:inline-block">
              <button
                className="uppercase tracking-wider py-4 px-6 w-full text-white font-semibold border border-main rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-main"
                type="button"
              >
                <a className="text-lg tracking-wide" href={`tel:${contact}`}>{contact}</a>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Cta;
