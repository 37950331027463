import Navbar from './components/Navbar';
import Hero from './components/Hero';
import About from './components/About';
import Contact from './components/Contact';
import Products from './components/Products';
import Services from './components/Services';
import Testimonials from './components/Testimonials';
import Cta from './components/Cta';
import Footer from './components/Footer';

function App() {
  return (
    <>
    <Navbar />
    <Hero />
    <About />
    <Products />
    <Services />
    <Testimonials />
    <Cta />
    <Contact />
    <Footer />
    </>
  );
}

export default App;
