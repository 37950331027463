import React from "react";

function Testimonials() {
  return (
    <section id="testimonials" className="font-ques py-10 bg-gray-100 sm:py-16 lg:py-24">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="text-3xl font-bold leading-tight text-gray-800 sm:text-4xl lg:text-5xl">
            Trusted by <span className="text-main">1000+</span> Sellers & Users
          </h2>
        </div>

        <div className="grid max-w-xl grid-cols-1 mx-auto mt-8 text-center lg:max-w-full sm:mt-12 lg:mt-20 lg:grid-cols-3 gap-x-6 xl:gap-x-12 gap-y-6">
          <div className="overflow-hidden bg-white rounded-md shadow">
            <div className="px-8 py-12">
              <blockquote className="mt-7">
                <p className="text-lg text-black">
                  “D.D Suppliers has been a game-changer for my restaurant. Their import/export services have made it easy to source high-quality food products and utensils, and their wholesale pricing is very competitive.”
                </p>
              </blockquote>
              <p className="text-base font-semibold tex-tblack mt-9">
                Dipak Thakur
              </p>
              <p className="mt-1 text-base text-gray-600">
                Teacher
              </p>
            </div>
          </div>

          <div className="overflow-hidden bg-white rounded-md shadow">
            <div className="px-8 py-12">
              <blockquote className="mt-7">
                <p className="text-lg text-black">
                  “I recently purchased some utensils from D.D Suppliers, and I was impressed by the variety and quality of products available. They have a great selection, and the customer service is fantastic.”
                </p>
              </blockquote>
              <p className="text-base font-semibold tex-tblack mt-9">Puskar Pokhrel</p>
              <p className="mt-1 text-base text-gray-600">Math Professor</p>
            </div>
          </div>

          <div className="overflow-hidden bg-white rounded-md shadow">
            <div className="px-8 py-12">
              <blockquote className="mt-7">
                <p className="text-lg text-black">
                  “I've been ordering imported food products from D.D Suppliers for a few months now and have never been disappointed. The quality is always top-notch, and the prices are very reasonable.”
                </p>
              </blockquote>
              <p className="text-base font-semibold tex-tblack mt-9">
                Krishna Sitaula
              </p>
              <p className="mt-1 text-base text-gray-600">Retail Seller</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
