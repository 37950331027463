import React from "react";

function Footer() {
  return (
    <section className="font-ques bg-darken">
      <footer className="container mx-auto p-4 rounded-lg md:flex md:items-center md:justify-between md:p-6">
        <span className="text-md text-black sm:text-center">
          © 2023
          <a href="" className="hover:underline">
            DDSuppliers
          </a>
          . All Rights Reserved.
        </span>
        <ul className="flex flex-wrap items-center mt-3 text-md text-black sm:mt-0">
          <li>
            <a href="" className="mr-4 hover:underline md:mr-6 ">
              About
            </a>
          </li>
          <li>
            <a href="" className="mr-4 hover:underline md:mr-6">
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="#" className="mr-4 hover:underline md:mr-6">
              Home
            </a>
          </li>
          <li>
            <a href="" className="hover:underline">
              Contact
            </a>
          </li>
        </ul>
      </footer>
    </section>
  );
}

export default Footer;
