import React from "react";
import product1 from "../assets/products/1.png";
import product2 from "../assets/products/2.png";
import product3 from "../assets/products/3.png";
import product4 from "../assets/products/4.png";

function Products() {
  return (
    <section
      id="products"
      className="font-ques py-2 md:py-16 container mx-auto px-3 md:px-0"
    >
      <div className="flex flex-col items-center justify-center pb-8 space-y-3">
        <h1 className="text-3xl md:text-4xl uppercase font-bold text-center">
          our products
        </h1>
        <p className="w-48 h-3 bg-main"></p>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-center space-y-3 md:space-x-6">
        <img className="h-64 w-96 object-cover shadow-md rounded" src={product2} alt="" />
        <div className="space-y-6">
          <img className="h-64 w-96 object-cover shadow-md rounded" src={product1} alt="" />
          <img className="h-64 w-96 object-cover shadow-md rounded" src={product3} alt="" />
        </div>
        <img className="h-64 w-96 object-cover shadow-md rounded" src={product4} alt="" />
      </div>
    </section>
  );
}

export default Products;
