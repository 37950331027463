import React from "react";

function About() {
  return (
    <section className="font-ques py-10 bg-white sm:py-16 lg:py-24">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="grid items-center grid-cols-1 lg:grid-cols-2 gap-x-12 xl:gap-x-24 gap-y-12">
          <div className="relative lg:mb-12">
            <img
              className="absolute -right-0 -bottom-8 xl:-bottom-12 xl:-right-4"
              src="https://cdn.rareblocks.xyz/collection/celebration/images/content/3/dots-pattern.svg"
              alt=""
            />
            <div className="pl-12 pr-6">
              <img
                className="relative"
                src="https://cdn.rareblocks.xyz/collection/celebration/images/content/3/girl-working-on-laptop.jpg"
                alt=""
              />
            </div>
            <div className="absolute left-0 pr-12 bottom-8 xl:bottom-20">
              <div className="max-w-xs bg-blue-600 rounded-lg sm:max-w-md xl:max-w-md">
                <div className="px-3 py-4 sm:px-5 sm:py-8">
                  <div className="flex items-start">
                    <p className="text-3xl sm:text-4xl">👋</p>
                    <blockquote className="ml-5">
                      <p className="text-sm font-medium text-white sm:text-lg">
                        “I recently purchased some cooking utensils from
                        DD Suppliers, and I couldn't be happier with my
                        purchase. The quality of the products is outstanding,
                        and the customer service was exceptional. I would highly
                        recommend DD Suppliers to anyone in need of
                        high-quality food products and utensils.”
                      </p>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="2xl:pl-16">
            <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl lg:leading-tight">
              We provide the best possible service
            </h2>
            <p className="text-xl leading-relaxed text-gray-900 mt-9">
              At D.D Suppliers, we are dedicated to providing our customers with
              high-quality food products and utensils from around the world. We
              believe that everyone should have access to the best products at
              affordable prices, which is why we work hard to source the best
              products from the most reputable suppliers.
            </p>
            <p className="mt-6 text-xl leading-relaxed text-gray-900">
              Whether you are a home cook, a professional chef, or simply
              someone who enjoys high-quality food and utensils, we are here to
              provide you with the best products and service possible.
            </p>
            <br />
            <a href="#" className="bg-main px-6 py-3 rounded text-white">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
